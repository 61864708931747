import type { Entities } from '@inwink/entities/entities';
import type { IInwinkEntityV3QueryOptions } from 'api/front';
import type { IEventRequests } from '../../../services/apiaccessprovider.definition';
import { defaultPostHeaderForJsonData } from '../../../api/front/index';

export function getActiveNotifications(eventRequests: IEventRequests): Promise<Entities.IUserNotification[]> {
    return import("../../../api/front/datasource").then((mod) => {
        const ds = mod.getFrontEntityDataSource<Entities.IUserNotification>(eventRequests.apiFront, 'person/notification', null);

        const options: IInwinkEntityV3QueryOptions = {};
        options.selects = {
            $all: true
        };
        options.order = [{ by: "creationDate", desc: true }];

        return ds.query(options, []).then((res) => res && res.data);
    });
}

export function dismissNotification(eventRequests: IEventRequests, notifid: string) {
    const url = `person/notification/${notifid}/dismiss`;
    return eventRequests.apiFront.postEventJson(url);
}

export function dismissNotifications(eventRequests: IEventRequests, notificationIds: string[]) {
    const url = `person/notification/dismiss`;
    return eventRequests.apiFront.postEventJson(url, JSON.stringify({ ids: notificationIds }), defaultPostHeaderForJsonData);
}

export function updateNotificationPreferences(eventRequests: IEventRequests, preferences) {
    return eventRequests.apiFront.postEventJson("person/notification/updatepreferences",
        JSON.stringify(preferences), defaultPostHeaderForJsonData);
}
