import { ILoggerBase } from '@inwink/logging/logging/basetypes';
import { Entities } from '@inwink/entities/entities';
import * as moment from 'moment';
import { processEntities } from './utils';
import { getDate } from '../../../../data/syncutils';
import { States } from '../../../../services/services';
import { partnerMeetingFeedbackDatasource, getPartnerMeetingFeedbackTemplate } from '../../api/exhibitormeetingfeedback';
import { meetingFeedbackDatasource, getMeetingFeedbackTemplate } from '../../api/meetingfeedback';
import { IEventRequests } from '../../../../services/apiaccessprovider.definition';
import { hasAccountRole } from '@@event/helpers';
// eslint-disable-next-line import/order

export function syncUserMeetingFeedbacks(eventRequests: IEventRequests, logger: ILoggerBase,
    eventconf: Entities.IEventDetailConfiguration, eventData: States.IEventDataStore, userDetail: Entities.IAppUser,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    userData: States.IPersonDataStore, force: boolean, trackProgress?) {
    let hasData = false;
    let hasEventData = false;

    const syncSingleTemplate = (entityname) => (template) => {
        const existing = eventData.fieldtemplates.data.filter((t) => t.entityName === entityname)[0];

        if (existing) {
            const existingstamp = JSON.stringify(existing.template);
            const stamp = JSON.stringify(template);
            if (existingstamp !== stamp) {
                hasEventData = true;
                existing.template = template;
                eventData.fieldtemplates.update(existing);
            }
        } else {
            hasEventData = true;
            eventData.fieldtemplates.insert({
                entityName: entityname,
                template: template
            } as any);
        }
    };

    let promises = [
        getMeetingFeedbackTemplate(eventRequests).then(syncSingleTemplate("MeetingFeedback")),
        syncUserMeetingFeedbacksFor(eventRequests, null, logger, eventconf, userDetail, userData, false).then((sHaveData) => {
            hasData = hasData || sHaveData;
        })
    ];

    if (userDetail && userDetail.exhibitorAccounts && userDetail.exhibitorAccounts.length) {
        userDetail.exhibitorAccounts.filter((ea) => hasAccountRole(ea.roles, "admin")).forEach((ea) => {
            promises.push(getPartnerMeetingFeedbackTemplate(eventRequests, ea.exhibitorId)
                .then(syncSingleTemplate("MeetingFeedback-" + ea.exhibitorId)));
            promises = [syncUserMeetingFeedbacksFor(eventRequests, ea.exhibitorId, logger, eventconf,
                userDetail, userData, false).then((sHaveData) => {
                hasData = hasData || sHaveData;
            })];
        });
    }

    return Promise.all(promises).then(() => {
        return hasData;
    }).then(() => {
        if (hasEventData) {
            return eventData.save();
        }
    });
}

export function syncUserMeetingFeedbacksFor(eventRequests: IEventRequests, exhibitorId: string,
    logger: ILoggerBase, eventconf: Entities.IEventDetailConfiguration, userDetail: Entities.IAppUser,
    userData: States.IPersonDataStore, force: boolean, trackProgress?) {
    const hasData = false;

    return processEntities<Entities.IMeetingFeedback>(
        logger,
        userData,
        force,
        "meetingfeedbacks",
        (lastsync) => {
            let filters;

            if (lastsync) {
                filters = { lastUpdate: { $gt: getDate(moment(lastsync), true)}};
            }

            if (exhibitorId) {
                return partnerMeetingFeedbackDatasource(
                    eventRequests, exhibitorId
                ).then((ds) => ds.getAll({
                    selects: {
                        $all: true
                    },
                    order: [{ by: "lastUpdate"}],
                    filters: filters
                })).then((res) => res.data);
            }
            return meetingFeedbackDatasource(eventRequests).then((ds) => ds.getAll({
                selects: {
                    $all: true
                },
                order: [{ by: "lastUpdate"}],
                filters: filters
            })).then((res) => res.data);
        },
        (feedbacks) => {
            if (feedbacks) {
                feedbacks.forEach((_mf) => {
                    const mf = _mf;
                    const existing = userData.meetingfeedbacks.data.filter((u) => u.id === mf.id)[0];
                    if (!existing) {
                        userData.meetingfeedbacks.insert(mf);
                    } else {
                        (mf as any).$loki = (existing as any).$loki;
                        (mf as any).meta = (existing as any).meta;
                        userData.meetingfeedbacks.update(mf as any);
                    }
                });
            }
        },
        trackProgress
    ).then(() => {
        return hasData;
    });
}
