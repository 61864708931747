import type { Entities } from '@inwink/entities/entities';
import { defaultPostHeaderForJsonData } from '../../../api/front/index';
import type { IEventRequests, IEventRequestManager } from '../../../services/apiaccessprovider.definition';

export function personFavoriteEventThemeDatasouce(requestManager: IEventRequestManager) {
    return import("../../../api/front/datasource").then((mod) => {
        return mod.getFrontEntityDataSource<Entities.IPersonFavoriteEventTheme>(
            requestManager, "person/favorite/eventtheme", "PersonFavoriteEventTheme"
        );
    });
}

export function addPersonFavoriteEventTheme(eventRequests: IEventRequests, eventThemeId: string) {
    return eventRequests.apiFront.postEventJson(`person/favorite/eventtheme/${eventThemeId}`,
        JSON.stringify({}), defaultPostHeaderForJsonData);
}

export function deletePersonFavoriteEventTheme(eventRequests: IEventRequests, eventThemeId: string) {
    return eventRequests.apiFront.postEventJson(`person/favorite/eventtheme/${eventThemeId}/delete`,
        JSON.stringify({}), defaultPostHeaderForJsonData);
}
