import type { Entities } from '@inwink/entities/entities';
import { defaultPostHeaderForJsonData } from '../../../api/front/index';
import type { IEventRequests, IEventRequestManager } from '../../../services/apiaccessprovider.definition';

export function personFavoriteExhibitorOfferingDatasouce(requestManager: IEventRequestManager) {
    return import("../../../api/front/datasource").then((mod) => {
        return mod.getFrontEntityDataSource<Entities.IPersonFavoriteExhibitorOffering>(
            requestManager, "person/favorite/exhibitor-offering", "PersonFavoriteExhibitorOffering"
        );
    });
}

export function addPersonFavoriteExhibitorOffering(eventRequests: IEventRequests, exhibitorOfferingId: string) {
    return eventRequests.apiFront.postEventJson(`person/favorite/exhibitor-offering/${exhibitorOfferingId}`,
        JSON.stringify({}), defaultPostHeaderForJsonData);
}

export function deletePersonFavoriteExhibitorOffering(eventRequests: IEventRequests, exhibitorOfferingId: string) {
    return eventRequests.apiFront.postEventJson(`person/favorite/exhibitor-offering/${exhibitorOfferingId}/delete`,
        JSON.stringify({}), defaultPostHeaderForJsonData);
}

export function allowShareInfoWithExhibitorOffering(eventRequests: IEventRequests, exhibitorOfferingId: string) {
    return eventRequests.apiFront.postEventJson(`person/favorite/exhibitor-offering/${exhibitorOfferingId}/allowshareinfo`,
        JSON.stringify({}), defaultPostHeaderForJsonData);
}
