import type { Entities } from '@inwink/entities/entities';
import type { IEventRequests } from '../../../services/apiaccessprovider.definition';
import { defaultPostHeaderForJsonData } from '../../../api/front/index';

export function getMe(
    eventRequests: IEventRequests,
    selects,
    currentLanguage: string,
    timeout?: number,
    disableAuthenticationBubbling? : boolean
): Promise<Entities.IAppUser> {
    const options = { ...defaultPostHeaderForJsonData, timeout: timeout, disableAuthenticationBubbling };
    return eventRequests.apiFront.postEventJson<Entities.IAppUser>(
        `person/me?currentLng=${currentLanguage}`,
        JSON.stringify({
            selects: selects,
        }),
        options
    );
}
