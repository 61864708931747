import type { Entities } from '@inwink/entities/entities';
import type { IRemovedEntity } from '../../../api/front/datasource';
import type { IEventRequests } from '../../../services/apiaccessprovider.definition';

export function getDiscussionThreads(eventRequests: IEventRequests,
    fromDate?: Date): Promise<Entities.IDiscussionThread[]> {
    return eventRequests.apiFront.getEventJson("discussionthread/list" + (fromDate ? "?fromDate=" + fromDate.toISOString() : ""));
}

export function getRemovedDiscussionThreads(eventRequests: IEventRequests, fromDate?: string): Promise<IRemovedEntity[]> {
    return eventRequests.apiFront.getEventJson("discussionthread/removed" + (fromDate ? "?since=" + fromDate : ""));
}

export function getDiscussionThreadMessages(eventRequests: IEventRequests,
    fromDate?: Date): Promise<Entities.IDiscussionThreadMessage[]> {
    return eventRequests.apiFront.getEventJson("discussionthread/message/list"
        + (fromDate ? "?fromDate=" + fromDate.toISOString() : ""));
}
