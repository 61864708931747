import { ILoggerBase } from '@inwink/logging/logging/basetypes';
import { logging } from '@inwink/logging';
import { States } from '@@services/services';

const datalogger = logging.getLogger("Data");

export function shouldForceSync(eventData: States.IPersonDataStore, logcontext?): boolean {
    let logger: ILoggerBase = datalogger;
    if (logcontext) {
        logger = datalogger.context(logcontext);
    }
    const lastSync = eventData.lastsyncs.data.find((s) => s.key === "fullsync");
    if (lastSync) {
        const dt = new Date(lastSync.date as string);
        const diff = ((new Date() as any) - (dt as any)) / (1000 * 60);
        logger.debug("last full sync was " + diff + "min ago");
        if (diff > 120) {
            return true;
        }
    }

    return false;
}
