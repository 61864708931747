import type { Entities } from '@inwink/entities/entities';
import type { IEventRequests } from '../../../services/apiaccessprovider.definition';
import { defaultPostHeaderForJsonData } from '../../../api/front/index';

export function setExhibitorScanTemplate(eventRequests: IEventRequests, exhibitorId: string,
    template: Entities.IEntityTemplate): Promise<any> {
    return eventRequests.apiFront.postJson(`exhibitor/${eventRequests.apiFront.eventId}/${exhibitorId}/exhibitorscantemplate`,
        JSON.stringify(template), defaultPostHeaderForJsonData);
}

export function getExhibitorScanTemplate(eventRequests: IEventRequests, exhibitorId: string): Promise<any> {
    return eventRequests.apiFront.getJson(`exhibitor/${eventRequests.apiFront.eventId}/${exhibitorId}/exhibitorscantemplate`);
}
