import { ILoggerBase } from '@inwink/logging/logging/basetypes';
import { Entities } from '@inwink/entities/entities';
import * as moment from 'moment';
import { States } from '../../../../services/services';
import { syncEntity } from '../../../../data/syncutils';
import { IEventRequests } from '../../../../services/apiaccessprovider.definition';

function contactRequests() {
    return import("../../api/contactrequests");
}

export function syncUserReceivedContactRequests(eventRequests: IEventRequests, logger: ILoggerBase,
    eventconf: Entities.IEventDetailConfiguration, userData: States.IPersonDataStore, force: boolean, trackProgress?) {
    let hasData = false;
    return syncEntity<Entities.IContactRequest>({
        requestMgr: eventRequests.apiFront,
        logger,
        dataStore: userData,
        force,
        apiCallProvider: (lastsync) => {
            const syncdate = lastsync ? moment(lastsync).utc().toISOString() : null;
            return contactRequests().then((mod) => {
                return mod.getReceivedContactRequests(eventRequests, syncdate);
            });
        },
        collectionname: "receivedContactRequests",
        itemkey: (r, s) => {
            return r.personId === s.personId;
        },
        itemcallback: () => {
            hasData = true;
        },
        trackProgress
    }).then(() => {
        return hasData;
    });
}

export function syncUserSendedContactRequests(eventRequests: IEventRequests, logger: ILoggerBase,
    eventconf: Entities.IEventDetailConfiguration, userData: States.IPersonDataStore, force: boolean, trackProgress?) {
    let hasData = false;
    return syncEntity<Entities.IContactRequest>({
        requestMgr: eventRequests.apiFront,
        logger,
        dataStore: userData,
        force,
        apiCallProvider: (lastsync) => {
            const syncdate = lastsync ? moment(lastsync).utc().toISOString() : null;
            return contactRequests().then((mod) => {
                return mod.getSendedContactRequests(eventRequests, syncdate);
            });
        },
        collectionname: "sendedContactRequests",
        itemkey: (r, s) => {
            return r.targetPersonId === s.targetPersonId;
        },
        itemcallback: () => {
            hasData = true;
        },
        trackProgress
    }).then(() => {
        return hasData;
    });
}
