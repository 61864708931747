import type { Entities } from '@inwink/entities/entities';
import { defaultPostHeaderForJsonData } from '../../../api/front/index';
import type { IEventRequests, IEventRequestManager } from '../../../services/apiaccessprovider.definition';

export function personFavoriteExhibitorDatasouce(requestManager: IEventRequestManager) {
    return import("../../../api/front/datasource").then((mod) => {
        return mod.getFrontEntityDataSource<Entities.IPersonFavoriteExhibitor>(
            requestManager, "person/favorite/exhibitor", "PersonFavoriteExhibitor"
        );
    });
}

export function addPersonFavoriteExhibitor(eventRequests: IEventRequests, exhibitorId: string) {
    return eventRequests.apiFront.postEventJson(`person/favorite/exhibitor/${exhibitorId}`,
        JSON.stringify({}), defaultPostHeaderForJsonData);
}

export function deletePersonFavoriteExhibitor(eventRequests: IEventRequests, exhibitorId: string) {
    return eventRequests.apiFront.postEventJson(`person/favorite/exhibitor/${exhibitorId}/delete`,
        JSON.stringify({}), defaultPostHeaderForJsonData);
}

export function allowShareInfoWithExhibitor(eventRequests: IEventRequests, exhibitorId: string) {
    return eventRequests.apiFront.postEventJson(`person/favorite/exhibitor/${exhibitorId}/allowshareinfo`,
        JSON.stringify({}), defaultPostHeaderForJsonData);
}
