import { Loki, Collection } from '@inwink/inwinkdb';
import * as moment from 'moment';
import { Entities } from '@inwink/entities/entities';
import { logging } from '@inwink/logging';
import * as Data from '../../../data';
import { States } from '../../../services/services';
import { sendEvent } from '../../../services/realtimeservice';

const logger = logging.getLogger("Data");

export interface IDiscussionThreadDb {
    db: Loki;
    messages: Collection<Entities.IDiscussionThreadMessage>;
    save(): Promise<any>;
}

export function getDiscussionMessages(userid, eventid, threadid) {
    let options;
    const Adapter = Data.getDefaultAdapter();
    const db = new Loki(InWinkEnv + "-" + userid + "-" + eventid + '-' + threadid + '.json', options);
    db.initializePersistence({
        adapter: Adapter ? new Adapter() : null
    });
    const loadData = () => {
        return db.loadDatabase(null, true).then(() => {
            let col = db.getCollection<Entities.IDiscussionThreadMessage>("messages");
            if (!col) {
                col = db.addCollection<Entities.IDiscussionThreadMessage>("messages", { unique: ['id'] });
            }

            const result = {
                db: db,
                messages: col,
                save() {
                    return db.saveDatabase().then(() => {

                    }, (err) => {
                        logger.error("error saving discussion", err);
                    });
                }
            };

            return result;
        }, (err) => {
            console.error("error loading data", err);
        });
    };

    return loadData();
}

export function appendMessages(
    userData: States.IPersonDataStore,
    threadid: string,
    messages: Entities.IDiscussionThreadMessage[],
    markUnread: boolean
) {
    let hasNewMessages = false;

    return getDiscussionMessages(userData.userId, userData.eventId, threadid).then((discussion) => {
        messages.forEach((m) => {
            const existing = discussion.messages.data.find((msg) => msg.id === m.id);
            if (!existing) {
                discussion.messages.insert(m);
                hasNewMessages = true;
            } else {
                Object.assign(existing, m);
                discussion.messages.update(existing);
            }
        });

        setLastMessage(userData, threadid, messages[messages.length - 1], markUnread && hasNewMessages);

        if (hasNewMessages && typeof window !== "undefined") {
            sendEvent("inwink.discussion." + threadid, { type: "newmessages" });
        }

        return discussion.save().then(() => {
            return hasNewMessages;
        });
    });
}

// deprecated, do not use
export function markAsRead(
    userData: States.IPersonDataStore,
    threadid: string
) {
    const discussion = userData.discussionThreads.data.find((dt) => dt.id === threadid);
    if (discussion && discussion.hasUnread) {
        discussion.hasUnread = false;
        userData.discussionThreads.update(discussion);
        return userData.save();
    }

    return Promise.resolve();
}

export function setLastMessage(
    userData: States.IPersonDataStore,
    threadid: string,
    message: Entities.IDiscussionThreadMessage,
    markUnread: boolean
) {
    const existing = userData.discussionThreads.data.find((dt) => dt.id === threadid);
    if (existing) {
        //     userData.discussionThreads.insert({
        //         id: threadid,
        //         thread: null,
        //         hasUnread: markUnread,
        //         lastMessage: message,
        //         lastMessageDate: message.messageDate,
        //         lastReadDate: new Date().toISOString()
        //     });
        // } else {
        existing.lastMessageDate = message.messageDate;
        if (markUnread) {
            if (!setUnreadState(existing)) {
                existing.hasUnread = existing.hasUnread
                    || (markUnread && (!existing.lastMessage || message.id !== existing.lastMessage.id));
            }
        }
        existing.lastMessage = message;
        userData.discussionThreads.update(existing);
    }
}

export function setLastRead(
    userData: States.IPersonDataStore,
    threadid: string,
    lastReadDate: string | Date
) {
    const existing = userData.discussionThreads.data.find((dt) => dt.id === threadid);
    if (existing) {
        if (typeof lastReadDate === "string") {
            existing.lastReadDate = lastReadDate;
        } else {
            existing.lastReadDate = lastReadDate.toISOString();
        }
        existing.hasUnread = false;
        setUnreadState(existing);
        userData.discussionThreads.update(existing);
    }
}

export function setUnreadState(discuss: Entities.IDiscussionThreadWrapper) {
    const discussion = discuss;
    if (discussion.lastReadDate && discussion.lastMessageDate) {
        discussion.hasUnread = moment(discussion.lastReadDate) < moment(discussion.lastMessageDate);
        return true;
    }
    return false;
}
