import { ILoggerBase } from '@inwink/logging/logging/basetypes';
import { Entities } from '@inwink/entities/entities';
import { States } from '../../../../services/services';
import { getExhibitorScanTemplate } from '../../api/exhibitor.scan';
import { IEventRequests } from '../../../../services/apiaccessprovider.definition';

export function syncUserExhibitorScanTemplates(eventRequests: IEventRequests, logger: ILoggerBase,
    eventconf: Entities.IEventDetailConfiguration, eventData: States.IEventDataStore, userDetail: Entities.IAppUser,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    userData: States.IPersonDataStore, force: boolean, trackProgress?) {
    let hasData = false;

    const promises = [
        Promise.resolve()
    ];

    const syncSingleTemplate = (entityname) => (template) => {
        if (template) {
            const existing = eventData.fieldtemplates.data.filter((t) => t.entityName === entityname)[0];

            if (existing) {
                const existingstamp = JSON.stringify(existing.template);
                const stamp = JSON.stringify(template.template);
                if (existingstamp !== stamp) {
                    hasData = true;
                    existing.template = template.template;
                    eventData.fieldtemplates.update(existing);
                }
            } else {
                hasData = true;
                eventData.fieldtemplates.insert({
                    entityName: entityname,
                    template: template.template
                } as any);
            }
        }
    };

    if (userDetail && userDetail.exhibitorAccounts && userDetail.exhibitorAccounts.length) {
        userDetail.exhibitorAccounts.forEach((ea) => {
            promises.push(getExhibitorScanTemplate(eventRequests, ea.exhibitorId)
                .then(syncSingleTemplate("ExhibitorScan-" + ea.exhibitorId)));
        });
    }

    return Promise.all(promises).then(() => {
        if (hasData) {
            return eventData.save();
        }
    });
}
