import type { Entities } from '@inwink/entities/entities';
import { saveFile } from '@inwink/utils/savefile';
import { defaultPostHeaderForJsonData } from '../../../api/front/index';
import type { IEventRequests } from '../../../services/apiaccessprovider.definition';

export function partnerMeetingFeedbackDatasource(eventRequests: IEventRequests, exhibitorId: string) {
    return import("../../../api/front/datasource").then((mod) => {
        return mod.getFrontEntityDataSource<Entities.IMeetingFeedback>(eventRequests.apiFront,
            `partner/${exhibitorId}/meeting/feedback`, "MeetingFeedback");
    });
}

export function getPartnerMeetingFeedbackTemplate(eventRequests: IEventRequests, exhibitorId: string) {
    return eventRequests.apiFront.getEventJson(`partner/${exhibitorId}/meeting/feedback/template`);
}

export function partnerExhibitorMeetingFeedbackExport(eventRequests: IEventRequests, exhibitorId: string,
    title: string, lang: string, columnLabels: any): Promise<any> {
    return eventRequests.apiFront.postEventJson(`partner/${exhibitorId}/meeting/feedback/export`, JSON.stringify({
        lang: lang,
        title: title,
        labels: columnLabels
    }), defaultPostHeaderForJsonData).then((res: any) => {
        res.blob().then((blobContent) => {
            saveFile(blobContent, `${title}.xlsx`, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        });
    });
}
