import * as moment from 'moment';
import { ILoggerBase } from '@inwink/logging/logging/basetypes';
import { States } from '../../../../services/services';
import { syncEntity, getDate } from '../../../../data/syncutils';
import { personFavoriteEventThemeDatasouce } from '../../api/personfavoriteeventtheme';
import { IEventRequests } from '../../../../services/apiaccessprovider.definition';

export function syncFavoriteEventThemes(eventRequests: IEventRequests, logger: ILoggerBase,
    userData: States.IPersonDataStore, force: boolean, trackProgress?) {
    let hasData = false;
    const ds = personFavoriteEventThemeDatasouce(eventRequests.apiFront);
    return syncEntity<States.IUserFavoriteEventTheme>({
        requestMgr: eventRequests.apiFront,
        logger,
        dataStore: userData,
        force,
        apiCallProvider: (lastsync) => {
            let filters;
            if (lastsync) {
                filters = { lastUpdateDate: { $gt: getDate(moment(lastsync), true)}};
            }
            return ds.then((s) => s.getAll({
                selects: {
                    $all: true
                },
                order: [{ by: "registrationDate" }],
                filters
            })).then((res) => res.data);
        },
        collectionname: "favoriteEventThemes",
        itemkey: (r, s) => {
            return r.eventThemeId === s.eventThemeId;
        },
        itemcallback: () => {
            hasData = true;
        },
        trackProgress
    }).then(() => {
        return hasData;
    });
}
