import { ILoggerBase } from '@inwink/logging/logging/basetypes';
import { Entities } from '@inwink/entities/entities';
import { processCall } from './utils';
import { States } from '../../../../services/services';
import * as Notifications from '../../data/notifications';
import { getActiveNotifications } from '../../api/notification';
import { IEventRequests } from '../../../../services/apiaccessprovider.definition';

export function syncUserNotifications(eventRequests: IEventRequests, logger: ILoggerBase,
    eventconf: Entities.IEventDetailConfiguration,
    userData: States.IPersonDataStore,
    force: boolean,
    isRealtime: boolean,
    trackProgress?) {
    let hasData = false;
    return processCall<Entities.IUserNotification>(logger, userData, force, "notifications",
        () => getActiveNotifications(eventRequests, /* lastsync */), (notifs) => {
            hasData = hasData || notifs.length > 0;
            return syncNotifications(eventconf, userData, isRealtime, notifs);
        }, trackProgress).then(() => {
        return hasData;
    });
}

function syncNotifications(eventconf: Entities.IEventDetailConfiguration,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    uData: States.IPersonDataStore, isRealtime: boolean, _notifs: Entities.IUserNotification[]) {
    const userData = uData;
    // if (!notifs || !notifs.length) {
    //     return;
    // }

    return Notifications.getNotifications(userData.eventId, userData.userId).then((notifsdata) => {
        const syncResult = Notifications.syncNotifications(isRealtime, notifsdata, _notifs, eventconf, userData);
        userData.unreadNotifications = syncResult.notificationsCount;
        userData.unreadMessages = syncResult.messagesCount;
        return notifsdata.save().then(() => {
            return userData.unreadNotifications > 0;
        });
    });
}
