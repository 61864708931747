import type { ILoggerBase } from '@inwink/logging/logging/basetypes';
import type { Entities } from '@inwink/entities/entities';
import * as moment from 'moment';
import * as groupBy from 'lodash/groupBy';
import { processCall } from './utils';
import type { States } from '../../../../services/services';
import { setUnreadState } from '../../data/discussions';
import * as Discussions from '../../data/discussions';
import {
    getDiscussionThreads, getRemovedDiscussionThreads, getDiscussionThreadMessages
} from '../../api/discussionthread.sync';
import type { IRemovedEntity } from '../../../../api/front/datasource';
import type { IEventRequests } from '../../../../services/apiaccessprovider.definition';
import { trackEventError } from '@@event/api/tracking';

export function syncUserDiscussions(eventRequests: IEventRequests, logger: ILoggerBase,
    eventconf: Entities.IEventDetailConfiguration, userData: States.IPersonDataStore, force: boolean, trackProgress?) {
    let hasData = false;
    return processCall<Entities.IDiscussionThread>(
        logger,
        userData,
        force,
        "discussionThreads",
        (lastsync) => {
            return getDiscussionThreads(eventRequests, lastsync);
        },
        (discussions, dtSync) => {
            const defaultRes: Array<IRemovedEntity> = [];
            let promise = Promise.resolve(defaultRes);
            hasData = hasData || discussions.length > 0;
            if (!force && dtSync) {
                promise = getRemovedDiscussionThreads(eventRequests,
                    moment(dtSync).utc().toISOString()).then((res) => res, (err) => {
                    logger.error("error get removed discussionthreads", err);
                    return [];
                });
            }
            return promise.then((removedDiscussions) => syncDiscussions(eventconf, userData, discussions, removedDiscussions));
        },
        trackProgress
    ).then(() => {
        return hasData;
    });
}

export function syncUserRecentMessages(
    eventRequests: IEventRequests,
    eventid: string,
    logger: ILoggerBase,
    eventconf: Entities.IEventDetailConfiguration,
    userData: States.IPersonDataStore,
    force: boolean,
    trackProgress?
) {
    let hasData = false;
    return processCall<Entities.IDiscussionThread>(
        logger,
        userData,
        force,
        "recentMessages",
        (lastsync) => getDiscussionThreadMessages(eventRequests, lastsync), (messages, lastsync) => {
            hasData = hasData || messages.length > 0;
            return syncMessages(eventRequests, eventid, userData, messages, lastsync !== undefined);
        },
        trackProgress
    ).then(() => {
        return hasData;
    });
}

function syncDiscussions(eventconf: Entities.IEventDetailConfiguration, userData: States.IPersonDataStore,
    threads: Entities.IDiscussionThread[], removedDiscussions: IRemovedEntity[]) {
    let hasNew = false;
    threads.forEach((t) => {
        const existing = userData.discussionThreads.data.find((d) => d.id === t.id);
        if (!existing) {
            userData.discussionThreads.insert({
                id: t.id,
                thread: t,
                hasUnread: false,
                lastMessage: null,
                lastMessageDate: null,
                lastReadDate: t.lastReadDate
            });
            hasNew = true;
        } else {
            existing.lastReadDate = t.lastReadDate;
            existing.thread = t;
            setUnreadState(existing);
            userData.discussionThreads.update(existing);
        }
    });

    if (removedDiscussions && removedDiscussions.length) {
        removedDiscussions.forEach((t) => {
            const existing = userData.discussionThreads.data.find((d) => d.id === t.id);
            if (existing) {
                userData.discussionThreads.remove(existing);
            }
        });
    }

    return userData.save().then(() => {
        return hasNew;
    });
}

function syncMessages(
    eventRequests: IEventRequests,
    eventid: string,
    userData: States.IPersonDataStore,
    messages: Entities.IDiscussionThreadMessage[],
    isUpdate: boolean
) {
    const groupedMessages = groupBy(messages, (m) => m.discussionThreadId);
    const discussions = Object.keys(groupedMessages);
    const promises = [];
    let hasNewData = false;
    discussions.forEach((threadId) => {
        const gmessages = groupedMessages[threadId];
        promises.push(Discussions.appendMessages(
            userData,
            threadId,
            gmessages,
            isUpdate
        ).then((hasnew) => {
            if (hasnew === true) {
                hasNewData = true;
            }
        }, (err) => {
            trackEventError(eventRequests.apiFront, eventid, err, "error processing append discussion messages " + threadId);
            return Promise.reject(err);
        }));
    });

    return Promise.all(promises).then(() => {
        return userData.save();
    }).then(() => {
        return hasNewData;
    });
}
