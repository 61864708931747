import * as assign from 'lodash/assign';
import { Entities } from '@inwink/entities/entities';
import { ILoggerBase } from '@inwink/logging/logging/basetypes';
import { logging } from '@inwink/logging';
import { metadataSyncActions } from '@@services/appmetadataactions/sync';
import { actionQueue } from '@@actionsqueue/index';
import { IEventRequests } from '@@services/apiaccessprovider.definition';
import { eventUserBootstrapModule } from '@@routes/appmodules';
import { States } from '@@services/services';
import { checkIfNetworkingAllowed } from "../../../services/networkingservice";
import { syncUserMeetingFeedbacks } from './usersync/meetingfeedbacks';
import { syncUserSessions } from './usersync/usersessions';
import { syncUserMeeting } from './usersync/meeting';
import { syncUserContactRecommandations } from './usersync/contactreco';
import { syncUserReceivedContactRequests, syncUserSendedContactRequests } from './usersync/contactrequest';
import { syncUserDiscussions, syncUserRecentMessages } from './usersync/discussions';
import { syncUserNotifications } from './usersync/notifications';
import { syncLeads } from './usersync/leads';
import { syncFavoriteExhibitorOfferings, syncFavoriteExhibitors } from './usersync/favoriteexhibitor';
import { syncUserExhibitorScanTemplates } from './usersync/exhibitorscans';
import { shouldForceSync } from './sync.user.helpers';
import { getMe } from '../api/person.me';
import { syncModerators } from './usersync/moderators';
import { syncFavoriteEventThemes } from './usersync/favoriteeventtheme';

const datalogger = logging.getLogger("Data");

let userPollingInterval: any = null;

function syncUserData(eventRequests: IEventRequests, userData: States.IPersonDataStore,
    update: () => void, isRealtime: boolean, dispatch, getState, checkTime = false): Promise<void> {
    const state = getState() as States.IAppState;

    if (!userData || !state.user.currentUser.detail || !state.user.currentUser.detail.id) {
        return Promise.resolve();
    }

    let logger: ILoggerBase = datalogger;
    if (state.appMetaData.logcontext) {
        logger = datalogger.context(state.appMetaData.logcontext);
    } else {
        logger = datalogger.context({ IWEvent: state.event.eventid });
    }

    if (!userData.syncInProgess) {
        if (actionQueue.hasItems()) {
            const forceSync = shouldForceSync(userData);
            return userSync(eventRequests, userData, forceSync, dispatch, getState).then((res) => {
                if (res) {
                    update();
                }
            }, (err) => {
                logger.error("user sync error", err);
            });
        }

        if (checkTime) {
            const diff = (new Date() as any) - (lastLightSync || 0);
            if (diff < 2 * 60 * 1000) {
                logger.debug("no need to refresh user data");
                return Promise.resolve();
            }
        }

        logger.debug("refresh user data");
        return lightSync(eventRequests, userData, false, isRealtime, dispatch, getState).then((res) => {
            if (res) {
                update();
            }
        }, (err) => {
            logger.error("user sync error", err);
        });
    }

    return Promise.resolve();
}

export function checkUserData(eventRequests: IEventRequests, userData: States.IPersonDataStore,
    update: () => void, dispatch, getState): Promise<void> {
    return syncUserData(eventRequests, userData, update, dispatch, getState, true);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function startUserPolling(userData: States.IPersonDataStore, update: () => void, dispatch, getState) {
    // stopUserPolling();
    // userPollingInterval = setInterval(() => {
    //     syncUserData(userData, update, dispatch, getState);
    // }, pollingDelay);
}

export function stopUserPolling() {
    clearInterval(userPollingInterval);
    userPollingInterval = null;
}

export function syncDiscussions(
    eventRequests: IEventRequests,
    userData: States.IPersonDataStore,
    force: boolean,
    isRealtime: boolean,
    dispatch,
    getState: () => States.IAppState
): Promise<boolean> {
    return _lightSync((state, logger) => {
        let conf: Entities.IEventDetailConfiguration = null;
        let hasData = false;
        const promises: Promise<any>[] = [];
        if (state.event && state.event.detail && state.event.detail.configuration) {
            conf = state.event.detail.configuration;
            promises.push(syncUserNotifications(eventRequests, logger, conf, userData, force, isRealtime)
                .then((res) => { if (res === true) hasData = true; return res; }));
            promises.push(syncUserDiscussions(eventRequests, logger, conf, userData, force)
                .then((res) => { if (res === true) hasData = true; return res; }).then(() => {
                    return syncUserRecentMessages(eventRequests, state.event.eventid, logger, conf, userData, force)
                        .then((res) => { if (res === true) hasData = true; return res; });
                }));
            // promises.push(syncUserRecentMessages(eventRequests, logger, conf, userData, force)
            //     .then((res) => { if (res === true) hasData = true; return res; }).then(() => {
            //         return syncUserDiscussions(eventRequests, logger, conf, userData, force)
            //             .then((res) => { if (res === true) hasData = true; return res; });
            //     }));
        }
        return Promise.all(promises).then(() => {
            return hasData;
        });
    }, eventRequests, userData, dispatch, getState);
}

let lastLightSync;
export function lightSync(
    eventRequests: IEventRequests,
    userData: States.IPersonDataStore,
    force: boolean,
    isRealtime: boolean,
    dispatch,
    getState: () => States.IAppState
): Promise<boolean> {
    return _lightSync((state, logger) => {
        let hasData = false;
        let conf: Entities.IEventDetailConfiguration = null;
        const promises: Promise<any>[] = [];
        if (state.event && state.event.detail && state.event.detail.configuration) {
            conf = state.event.detail.configuration;
            const hasFavorites = conf.companion && (conf.companion.useFavorites || !conf.companion.disableUserCanLogin);
            const hasNetworking = checkIfNetworkingAllowed(conf.networking, state.user.currentUser.detail);
            const hasBusinessMeeting = conf.exhibitors?.businessMeetingConfig?.enabled;

            if (!hasNetworking && !hasFavorites && !hasBusinessMeeting) {
                return Promise.resolve(false);
            }

            const checkHasData = (res: any) => { if (res === true) hasData = true; return res; };

            promises.push(syncUserMeeting(eventRequests, logger, conf, userData, force).then(checkHasData));
            promises.push(syncUserNotifications(eventRequests, logger, conf, userData, force, isRealtime)
                .then(checkHasData));
            // promises.push(syncUserRecentMessages(eventRequests, logger, conf, userData, force)
            //     .then((res) => { if (res === true) hasData = true; return res; }).then(() => {
            //         return syncUserDiscussions(eventRequests, logger, conf, userData, force)
            //             .then((res) => { if (res === true) hasData = true; return res; });
            //     }));
            promises.push(syncUserDiscussions(eventRequests, logger, conf, userData, force)
                .then(checkHasData).then(() => {
                    return syncUserRecentMessages(eventRequests, state.event.eventid, logger, conf, userData, force)
                        .then(checkHasData);
                }));
            promises.push(syncUserReceivedContactRequests(eventRequests, logger, conf, userData, force)
                .then(checkHasData));
            promises.push(syncUserSendedContactRequests(eventRequests, logger, conf, userData, force)
                .then(checkHasData));
            promises.push(syncFavoriteExhibitors(eventRequests, logger, conf, userData, force)
                .then(checkHasData));
            promises.push(syncFavoriteExhibitorOfferings(eventRequests, logger, conf, userData, force)
                .then(checkHasData));
                
            promises.push(syncFavoriteEventThemes(eventRequests, logger, userData, force)
                .then(checkHasData));
        }

        return Promise.all(promises).then(() => {
            const forceSync = shouldForceSync(userData);
            if (hasData || forceSync) {
                return userSync(eventRequests, userData, forceSync, dispatch, getState).then((res) => {
                    return hasData || res;
                });
            }
            return hasData;
        });
    }, eventRequests, userData, dispatch, getState);
}

function _lightSync(promiseProvider: (state: States.IAppState, logger: ILoggerBase) => Promise<any>,
    eventRequests: IEventRequests, uData: States.IPersonDataStore, dispatch,
    getState: () => States.IAppState): Promise<boolean> {
    const userData = uData;
    const state = getState() as States.IAppState;
    let logger: ILoggerBase = datalogger;
    if (state.appMetaData && state.appMetaData.logcontext) {
        logger = datalogger.context(state.appMetaData.logcontext);
    }

    if (!state.user.currentUser.detail || !state.user.currentUser.detail.id) return Promise.resolve(false);

    const diff = (new Date() as any) - (lastLightSync || 0);
    if (diff < 200) {
        logger.info("too frequent sync...");
        return Promise.resolve(false);
    }
    lastLightSync = new Date();

    userData.syncInProgess = true;
    metadataSyncActions.isSyncingUserData(true)(dispatch);

    return promiseProvider(state, logger).then((res) => {
        return res;
    }, (err) => {
        logger.error("error syncing user data", err);
        userData.syncInProgess = false;
        metadataSyncActions.isSyncingUserData(false)(dispatch);
    }).then((res) => {
        userData.syncInProgess = false;
        metadataSyncActions.isSyncingUserData(false)(dispatch);
        return res;
    });
}

let lastSync;
export function userSync(eventRequests: IEventRequests, uData: States.IPersonDataStore,
    force: boolean, dispatch, getState: () => States.IAppState,
    progressCallback?: (arg: { progressPercent: number }) => void): Promise<boolean> {
    const userData = uData;
    const state = getState();
    let logger: ILoggerBase = datalogger;
    if (state.appMetaData.logcontext) {
        logger = datalogger.context(state.appMetaData.logcontext);
    }

    if (!userData || !state.user.currentUser.detail || !state.user.currentUser.detail.id) return Promise.resolve(false);

    if (userData.syncInProgess) return Promise.resolve(false);

    const diff = (new Date() as any) - (lastSync || 0);
    if (!force && diff < 200) {
        logger.info("too frequent user sync...");
        return Promise.resolve(false);
    }
    lastSync = new Date();

    const totalSteps = 10;
    let doneSteps = 0;
    const progress = {
        progressPercent: 0
    };
    metadataSyncActions.isSyncingUserData(true)(dispatch);
    const trackProgress = () => {
        doneSteps++;
        progress.progressPercent = (doneSteps / totalSteps) * 100;
        if (progressCallback) progressCallback(progress);
    };
    logger.debug("start user sync force:" + force);
    let hasData = false;

    userData.syncInProgess = true;
    return actionQueue.processQueue(dispatch, getState).then(() => {
        const state2 = getState();
        // il faut laisser un appel simple pour démarrer la synchro pour s'assurer que l'access token est toujours good
        // ensuite on peut parallèliser
        return getMe(eventRequests, {
            $all: true,
            availabilities: {
                $all: true
            },
            exhibitorAccounts: {
                $all: true,
                exhibitor: {
                    $all: true,
                    badgeQuotaInfos: {
                        $all: true
                    },
                    eventThemes: {
                        $all: true
                    }
                }
            }
        }, state2.i18n.currentLanguageCode, 5000).then((userdetail) => {
            const strme = JSON.stringify(userdetail);
            const user = assign({}, state2.user.currentUser.detail);
            if (user.meta) delete user.meta;
            if (user.$loki) delete user.$loki;
            const struser = JSON.stringify(user);
            if (strme !== struser) {
                hasData = true;
                return eventUserBootstrapModule().then((mod) => {
                    mod.currentUserActions.setCurrentUser(userdetail, state2.user.currentUser.data)(dispatch, getState);
                    return userdetail;
                });
            }
            return userdetail;
        }).then((userdetail) => {
            if (userData.userDetail && userData.userDetail.data.length) {
                const existing = userData.userDetail.data[0];
                // le allownetworking peut être non présent (si null) du coup dans userdetail il n'est pas pris en compte
                delete existing.allowNetworking;
                assign(existing, userdetail);
                userData.userDetail.update(existing);
            } else {
                userData.userDetail.insert(userdetail);
            }

            if (hasData) {
                return userData.save();
            }
        }).then(trackProgress, (err) => {
            trackProgress();
            return Promise.reject(err);
        });
    }).then(() => {
        const state2 = getState();
        let conf = null;
        if (state2.event.detail && state2.event.detail.configuration) {
            conf = state2.event.detail.configuration;
        }

        return Promise.all([
            syncUserSessions(eventRequests, logger, conf, userData, force, trackProgress)
                .then((res) => { if (res === true) hasData = true; return res; })
        ]);
    }).then(() => {
        const state2 = getState();
        let conf = null;

        if (state2.event.detail && state2.event.detail.configuration) {
            conf = state2.event.detail.configuration;
        }

        const processData = (res) => { if (res === true) hasData = true; return res; };

        const userDetail = userData.userDetail.data[0];

        return Promise.all([
            syncFavoriteExhibitors(eventRequests, logger, conf, userData, force, trackProgress).then(processData),
            syncFavoriteExhibitorOfferings(eventRequests, logger, conf, userData, force, trackProgress).then(processData),
            syncFavoriteEventThemes(eventRequests, logger, userData, force, trackProgress).then(processData),
            syncUserExhibitorScanTemplates(eventRequests, logger, conf, state2.event.data,
                userDetail, userData, force, trackProgress).then(processData),
            // syncFavoriteExhibitors(eventRequests, logger, conf, userData, force, trackProgress).then(processData),
            syncLeads(eventRequests, logger, conf, userData, force, trackProgress).then(processData),
            syncModerators(eventRequests, logger, conf, userData, force, trackProgress).then(processData)
        ]);
    })
        .then(() => {
            const state2 = getState();
            let conf: Entities.IEventDetailConfiguration = null;
            const promises: Promise<any>[] = [];

            const processData = (res) => { if (res === true) hasData = true; return res; };

            const userDetail = userData.userDetail.data[0];

            if (state2.event.detail && state2.event.detail.configuration) {
                conf = state2.event.detail.configuration;
                const hasNetworking = checkIfNetworkingAllowed(conf.networking, state2.user.currentUser.detail);
                const hasBusinessMeeting = conf.exhibitors?.businessMeetingConfig?.enabled;
                if (!hasNetworking && !hasBusinessMeeting) {
                    return;
                }

                promises.push(syncUserMeeting(eventRequests, logger, conf, userData, force, trackProgress).then(processData));
                promises.push(syncUserMeetingFeedbacks(eventRequests, logger, conf, state2.event.data, userDetail,
                    userData, force, trackProgress).then(processData));
                promises.push(syncUserReceivedContactRequests(eventRequests, logger, conf, userData,
                    force, trackProgress).then(processData));
                promises.push(syncUserSendedContactRequests(eventRequests, logger, conf, userData,
                    force, trackProgress).then(processData));
                promises.push(syncUserNotifications(eventRequests, logger, conf, userData,
                    force, false, trackProgress).then(processData));
                promises.push(syncUserDiscussions(eventRequests, logger, conf, userData, force, trackProgress)
                    .then(processData).then(() => {
                        return syncUserRecentMessages(
                            eventRequests, state.event.eventid, logger, conf, userData, force, trackProgress
                        ).then(processData);
                    }));
                // promises.push(syncUserRecentMessages(eventRequests, logger, conf, userData,
                //     force, trackProgress).then(processData).then(() => {
                //     return syncUserDiscussions(eventRequests, logger, conf, userData, force, trackProgress).then(processData);
                // }));
                promises.push(syncUserContactRecommandations(eventRequests, logger, conf, userData,
                    force, trackProgress).then(processData));
            }

            return Promise.all(promises);
        })
        .then(() => {
            logger.debug("user sync ok");

            let lastSync2 = userData.lastsyncs.data.find((s) => s.key === "coresync");
            if (!lastSync2) {
                lastSync2 = {
                    key: "coresync",
                    date: new Date()
                } as any;
                userData.lastsyncs.insert(lastSync2);
            } else {
                lastSync2.date = new Date();
                userData.lastsyncs.update(lastSync2);
            }

            let fullSync = userData.lastsyncs.data.find((s) => s.key === "fullsync");
            if (!fullSync) {
                fullSync = {
                    key: "fullsync",
                    date: new Date()
                } as any;
                userData.lastsyncs.insert(fullSync);
            } else if (force) {
                fullSync.date = new Date();
                userData.lastsyncs.update(fullSync);
            }

            return userData.save();
        })
        .then(() => {
            userData.syncInProgess = false;
            metadataSyncActions.isSyncingUserData(false)(dispatch);
            trackProgress();
            if (hasData) {
                dispatch({ type: "USER_DATACHANGED" });
            }
            return hasData;
        }, (err) => {
            userData.syncInProgess = false;
            metadataSyncActions.isSyncingUserData(false)(dispatch);
            trackProgress();
            if (hasData) {
                dispatch({ type: "USER_DATACHANGED" });
            }
            return Promise.reject(err);
        });
}
