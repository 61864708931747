import * as moment from 'moment';
import { ILoggerBase } from '@inwink/logging/logging/basetypes';
import { Entities } from '@inwink/entities/entities';
import { States } from '../../../../services/services';
import { syncEntity, getDate } from '../../../../data/syncutils';
import { personFavoriteExhibitorDatasouce } from '../../api/personfavoriteexhibitor';
import { IEventRequests } from '../../../../services/apiaccessprovider.definition';
import { personFavoriteExhibitorOfferingDatasouce } from '../../api/personfavoriteexhibitoroffering';

export function syncFavoriteExhibitors(eventRequests: IEventRequests, logger: ILoggerBase,
    eventconf: Entities.IEventDetailConfiguration, userData: States.IPersonDataStore, force: boolean, trackProgress?) {
    let hasData = false;
    const ds = personFavoriteExhibitorDatasouce(eventRequests.apiFront);
    return syncEntity<States.IUserFavoriteExhibitor>({
        requestMgr: eventRequests.apiFront,
        logger,
        dataStore: userData,
        force,
        apiCallProvider: (lastsync) => {
            let filters;
            if (lastsync) {
                filters = { lastUpdateDate: { $gt: getDate(moment(lastsync), true)}};
            }
            return ds.then((s) => s.getAll({
                selects: {
                    $all: true
                },
                order: [{ by: "registrationDate" }],
                filters
            })).then((res) => res.data);
        },
        collectionname: "favoriteExhibitors",
        itemkey: (r, s) => {
            return r.exhibitorId === s.exhibitorId;
        },
        itemcallback: () => {
            hasData = true;
        },
        trackProgress
    }).then(() => {
        return hasData;
    });
}

export function syncFavoriteExhibitorOfferings(eventRequests: IEventRequests, logger: ILoggerBase,
    eventconf: Entities.IEventDetailConfiguration, userData: States.IPersonDataStore, force: boolean, trackProgress?) {
    let hasData = false;
    const ds = personFavoriteExhibitorOfferingDatasouce(eventRequests.apiFront);
    return syncEntity<States.IUserFavoriteExhibitorOffering>({
        requestMgr: eventRequests.apiFront,
        logger,
        dataStore: userData,
        force,
        apiCallProvider: (lastsync) => {
            let filters;
            if (lastsync) {
                filters = { lastUpdateDate: { $gt: getDate(moment(lastsync), true)}};
            }
            return ds.then((s) => s.getAll({
                selects: {
                    $all: true
                },
                order: [{ by: "registrationDate" }],
                filters
            })).then((res) => res.data);
        },
        collectionname: "favoriteExhibitorOfferings",
        itemkey: (r, s) => {
            return r.exhibitorOfferingId === s.exhibitorOfferingId;
        },
        itemcallback: () => {
            hasData = true;
        },
        trackProgress
    }).then(() => {
        return hasData;
    });
}
