import type { Entities } from '@inwink/entities/entities';
import { defaultPostHeaderForJsonData } from '../../../api/front';
import type { IEventRequests } from '../../../services/apiaccessprovider.definition';

export function meetingFeedbackDatasource(eventRequests: IEventRequests) {
    return import("../../../api/front/datasource").then((mod) => {
        return mod.getFrontEntityDataSource<Entities.IMeetingFeedback>(
            eventRequests.apiFront, `meeting/feedback`, "MeetingFeedback"
        );
    });
}

export function getMeetingFeedbackTemplate(eventRequests: IEventRequests) {
    return eventRequests.apiFront.getEventJson("meeting/feedback/template");
}

export function sendMeetingFeedback(eventRequests: IEventRequests, meetingId: string, feedback)
    : Promise<Entities.IMeetingFeedback> {
    return eventRequests.apiFront.postEventJson<Entities.IMeetingFeedback>(`meeting/${meetingId}/feedback`,
        JSON.stringify(feedback), defaultPostHeaderForJsonData);
}
