import { Entities } from '@inwink/entities/entities';
import { ILoggerBase } from '@inwink/logging/logging/basetypes';
import { States } from '../../../../services/services';
import { IInwinkEntityV3QueryOptions } from '../../../../api/front';
import { moderatorDatasourceV3 } from '../../api/moderators';
import { syncRecursiveEntity } from '../../../../data/syncutils';
import { IEventRequests } from '../../../../services/apiaccessprovider.definition';

export function syncModerators(eventRequests: IEventRequests, logger: ILoggerBase,
    eventconf: Entities.IEventDetailConfiguration,
    userData: States.IPersonDataStore, force: boolean, trackProgress?): Promise<any> {
    let hasChanges = false;
    // if (!args.configuration || !args.configuration.content || !args.configuration.content.hasSessions) {
    //     if (args.trackProgress) {
    //         args.trackProgress({ collectionname: "sessions", progressPercent: 100 })
    //     }
    //     return Promise.resolve();
    // }

    const getExpr = () => {
        const initialOption: IInwinkEntityV3QueryOptions = {
            order: [{ by: "id" }],
            selects: {
                $all: true
            }
        };

        return initialOption;
    };

    const itemkey = (r, m: Entities.IModerator) => { return r.id === m.id; };
    const itemcallback = () => {
        hasChanges = true;
    };

    return syncRecursiveEntity<Entities.IModerator>({
        datasource: moderatorDatasourceV3(eventRequests.apiFront),
        logger: logger,
        data: userData,
        force: force,
        getBaseOptions: getExpr,
        collectionname: "moderators",
        itemkey: itemkey,
        itemcallback: itemcallback,
        progressCallback: trackProgress,
        itemsPerRequest: 100,
        removedProvider: () => {
            // pas de gestion de removed sur les modérateurs
            return Promise.resolve(null);
        }
    }).then(() => hasChanges);
}
