import type { ILoggerBase } from '@inwink/logging/logging/basetypes';
import type { Entities } from '@inwink/entities/entities';
import { processEntities } from './utils';
import type { States } from '../../../../services/services';
import { sessionPersonDatasouce } from '../../api/sessionperson';
import type { IEventRequests } from '../../../../services/apiaccessprovider.definition';

export function syncUserSessions(eventRequests: IEventRequests, logger: ILoggerBase,
    eventconf: Entities.IEventDetailConfiguration, userData: States.IPersonDataStore, force: boolean, trackProgress?) {
    const hasData = false;
    return processEntities<Entities.ISessionPerson>(logger, userData, force, "sessionpersons",
        () => sessionPersonDatasouce(eventRequests.apiFront).then((ds) => ds.getAll({
            selects: {
                $all: true
            },
            order: [{by: "lastUpdateDate"}]
        })).then((res) => res && res.data), (sessions) => {
            syncSessionPerson(eventconf, userData, sessions);
        }, trackProgress).then(() => {
        return hasData;
    });
}

function syncSessionPerson(eventconf: Entities.IEventDetailConfiguration, userData: States.IPersonDataStore,
    sessionpersons: Entities.ISessionPerson[]) {
    if (sessionpersons && sessionpersons.length) {
        userData.registeredSessions.removeWhere(() => true);
        sessionpersons.forEach((c) => {
            userData.registeredSessions.insert(c);
        });
    }
}
